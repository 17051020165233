<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Editors component
 */
export default {
  page: {
    title: "Create Template",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      valid: true,
      submitted: false,
      title: "Create Template",
      items: [
        {
          text: "Template",
          href: "#",
        },
        {
          text: "Create Template",
          active: true,
        },
      ],

      slctTemp: null,
      slctTemp2: null,
      slctTempOptions: [],
      slctTempOptions2: [],
      content: "",
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor imagetools",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
    };
  },
  created() {
    this.TypeDetails();
  },
  validations: {
    slctTemp2: {
      required,
    },
    slctTemp: {
      required,
    },
    content: {
      required,
    },
  },
  methods: {
    async TypeDetails() {
      var result = await ApiClass.getRequest("admin/setting/temptypes", true);
      if (result.data.status_code == 1) {
        this.slctTempOptions = result.data.data.temptype;
        this.slctTempOptions2 = result.data.data.sendtype;
      }
    },
    async save() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      var obj = {
        type: this.slctTemp2.key,
        temp_type: this.slctTemp.key,
        content: this.content,
      };
      console.log(obj);
      var result = await ApiClass.postRequest(
        "admin/template/create",
        true,
        obj
      );
      this.submitted = false;
      this.slctTemp2 = this.slctTemp = this.content = ""; // Empty Fields
      Swal.fire({
        position: "center",
        icon: "success",
        title: result.data.message,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    onReset(event) {
      event.preventDefault();
      this.submitted = false;
      this.slctTemp2 = this.slctTemp = this.content = ""; // Empty Fields
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="save" @reset="onReset">
              <!-- Send Type -->
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.slctTemp2.$error }"
              >
                <p class="card-title-desc">Send Type:</p>
                <multiselect
                  v-model.trim="$v.slctTemp2.$model"
                  track-by="key"
                  label="value"
                  :options="slctTempOptions2"
                  required
                ></multiselect>
              </div>
              <div class="error" v-if="submitted && !$v.slctTemp2.required">
                Send Type is required
              </div>

              <!-- Template Type -->
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.slctTemp.$error }"
              >
                <p class="card-title-desc mt-3">Template Type:</p>
                <multiselect
                  v-model.trim="$v.slctTemp.$model"
                  track-by="key"
                  label="value"
                  :options="slctTempOptions"
                  required
                ></multiselect>
              </div>
              <div class="error" v-if="submitted && !$v.slctTemp.required">
                Template Type is required
              </div>

              <!-- Text Editor -->
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.content.$error }"
              >
                <p class="card-title-desc mt-3">Add Content :</p>
                <tinymce
                  id="d1"
                  v-model.trim="$v.content.$model"
                  :plugins="plugins"
                  :toolbar="toolbar"
                  :other_options="options"
                  required
                ></tinymce>
              </div>
              <div class="error" v-if="submitted && !$v.content.required">
                Content is required
              </div>

              <!-- Save Button -->
              <b-button class="mt-3" type="submit" variant="primary"
                >Submit</b-button
              >
              <b-button class="mt-3" type="reset" variant="danger"
                >Reset</b-button
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>